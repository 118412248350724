import { createI18n } from 'vue-i18n'

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}

export default createI18n({
  silentTranslationWarn: true,
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || 'fr',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'fr',
  messages: loadLocaleMessages(),
  datetimeFormats: {
    fr: {
      long: {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      }
    }
  },
  numberFormats: {
    fr: {
      currency: {
        style: 'currency',
        currency: 'EUR',
        useGrouping: true,
        currencyDisplay: 'symbol',
        notation: 'compact',
        minimumFractionDigits: 2
      },
      fullCurrency: {
        style: 'currency',
        currency: 'EUR',
        useGrouping: false,
        currencyDisplay: 'symbol',
        notation: 'standard',
        minimumFractionDigits: 2
      },
      decimal: {
        style: 'decimal',
        useGrouping: true,
        notation: 'compact',
        minimumFractionDigits: 2
      },
      fullDecimal: {
        style: 'decimal',
        useGrouping: false,
        notation: 'standard',
        minimumFractionDigits: 2
      }
    }
  }
})
